import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { BlockContainer, TitleBar, NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { CustomMuiDatatable } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import { MuiTableOptions } from 'app/constants';
import { customMuiTableThemeWithCursor } from './utils';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import {
  dateRangeFilterLabelSelector,
  vertSSIDFilterSelector
} from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { fetchStatePropTypes } from 'app/redux/utils';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  sfdcByPropertyDataSelector,
  sfdcCaseDetailsFetchStateSelector,
  fetchSfdcCaseDetails
} from 'app/redux/incidents';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { isArray, isEmpty } from 'lodash';

class SfdcBypropertyTable extends Component {
  componentDidUpdate = prevProps => {
    const {
      dateRangeFilterLabel,
      fetchSfdcCaseDetails,
      selectedPath
    } = this.props;
    const {
      dateRangeFilterLabel: prevDateRange,
      selectedPath: prevSelectedPath
    } = prevProps;

    const isNodeCustomerOrZone = isSpecificNodeType(selectedPath, 'customer');

    const pathChanged =
      prevSelectedPath && prevSelectedPath.id !== selectedPath.id;
    const dateRangeChanged = prevDateRange !== dateRangeFilterLabel;

    if ((isNodeCustomerOrZone && pathChanged) || dateRangeChanged) {
      fetchSfdcCaseDetails();
    }
  };
  render = () => {
    const {
      fetchSfdcCaseDetails,
      activitySummary = [],
      fetchState,
      selectedPath
    } = this.props;

    const { pending, complete, failed } = fetchState;
    const hasData = isArray(activitySummary) && !isEmpty(activitySummary);
    const noDataToShow = !pending && complete && !hasData;

    const isNodeCustomer = isSpecificNodeType(selectedPath, 'customer');

    const keys = activitySummary.reduce((acc, obj) => {
      for (let key of Object.keys(obj)) {
        acc.add(key);
      }
      return acc;
    }, new Set());
    const columns = [...keys].map(key => ({
      name: key,
      label: key,
      options: {
        display: true,
        sort: true,
        sortThirdClickReset: true
      }
    }));

    columns.forEach(column => {
      if (column.name === 'realm') {
        column.options.display = false;
      }
      if (column.name === 'zonename') {
        column.label = 'Property';
        column.order = 1;
      }
      if (column.name === 'total') {
        column.label = '#Incidents';
        column.order = 2;
      }
    });

    const resultColumn = columns.sort((a, b) => {
      if (
        (a.name === 'zonename' || a.name === 'total') &&
        (b.name === 'zonename' || b.name === 'total')
      ) {
        return a.order - b.order;
      }
      if (a.name === 'zonename' || a.name === 'total') {
        return -1;
      }
      if (b.name === 'zonename' || b.name === 'total') {
        return 1;
      }
      if (a.name.startsWith('P') && b.name.startsWith('P')) {
        const aNumber = parseInt(a.name.match(/P(\d+)/)[1], 10);
        const bNumber = parseInt(b.name.match(/P(\d+)/)[1], 10);
        return aNumber - bNumber;
      }
      if (a.name.startsWith('P')) {
        return 1;
      }
      if (b.name.startsWith('P')) {
        return -1;
      }
      return 0;
    });

    const tableOptions = {
      ...MuiTableOptions,
      rowsPerPage: 5,
      rowsPerPageOptions: [5, 10, 15, 100]
    };

    return (
      <BlockContainer>
        <TitleBar leftChildren="By Property" padUnderTitle={false} />
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={fetchSfdcCaseDetails}
        >
          {pending ? (
            <LoadingIconPlaceholder />
          ) : noDataToShow || failed ? (
            <NoDataToShow
              message={
                failed
                  ? 'There has been a problem fetching the incidents property'
                  : 'No SFDC incidents activity available'
              }
              style={{ background: 'none', position: 'relative' }}
            />
          ) : (
            isNodeCustomer && (
              <div
                className={pending || noDataToShow ? 'fetch-state-pending' : ''}
              >
                {complete && hasData && (
                  <MuiThemeProvider
                    theme={createMuiTheme(customMuiTableThemeWithCursor)}
                  >
                    <CustomMuiDatatable
                      tableKey="incident-by-property-table"
                      data={activitySummary}
                      columns={resultColumn}
                      options={tableOptions}
                    />
                  </MuiThemeProvider>
                )}
              </div>
            )
          )}
        </FailedFetchStateHandler>
      </BlockContainer>
    );
  };
}

SfdcBypropertyTable.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  vertSSIDFilter: PropTypes.object,
  fetchSfdcCaseDetails: PropTypes.func,
  activitySummary: PropTypes.array,
  fetchState: fetchStatePropTypes
};

const mapStateToProps = createSelector(
  vertSSIDFilterSelector,
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  sfdcByPropertyDataSelector,
  sfdcCaseDetailsFetchStateSelector,
  (
    vertSSIDFilter,
    dateRangeFilterLabel,
    selectedPath,
    activitySummary,
    fetchState
  ) => ({
    vertSSIDFilter,
    dateRangeFilterLabel,
    selectedPath,
    activitySummary,
    fetchState
  })
);

export default connect(mapStateToProps, { fetchSfdcCaseDetails })(
  SfdcBypropertyTable
);
